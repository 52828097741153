// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-markdown-blog-post-page-js": () => import("/opt/build/repo/src/templates/MarkdownBlogPostPage.js" /* webpackChunkName: "component---src-templates-markdown-blog-post-page-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("/opt/build/repo/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-js": () => import("/opt/build/repo/src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pricing-js": () => import("/opt/build/repo/src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-product-scheduling-js": () => import("/opt/build/repo/src/pages/product/scheduling.js" /* webpackChunkName: "component---src-pages-product-scheduling-js" */),
  "component---src-pages-product-tasks-js": () => import("/opt/build/repo/src/pages/product/tasks.js" /* webpackChunkName: "component---src-pages-product-tasks-js" */),
  "component---src-pages-product-welcome-dashboard-js": () => import("/opt/build/repo/src/pages/product/welcome-dashboard.js" /* webpackChunkName: "component---src-pages-product-welcome-dashboard-js" */)
}

